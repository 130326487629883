import GATSBY_COMPILED_MDX from "/opt/build/repo/blog/what-is-cms/index.mdx";
import * as React from "react";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Layout from "../../components/layout/Layout.component";
import * as style from "./Post.style.module.scss";
import Author from "../../components/author/Author.component";
import FeaturedPosts from "../../components/featured-posts/FeaturedPosts.component";
function BlogPost({data, children}) {
  const post = data.mdx.frontmatter;
  const image = getImage(data.mdx.frontmatter.hero_image);
  const featuredPosts = data.allMdx.nodes;
  return React.createElement(Layout, null, React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.hero_image_alt,
    as: "header",
    className: style.hero
  }), React.createElement("main", {
    className: style.post
  }, React.createElement("article", {
    className: style.article
  }, React.createElement("div", {
    className: style.date
  }, post.date, " | Blog"), React.createElement("h1", null, post.title), children), React.createElement("aside", null, React.createElement(Author), React.createElement(FeaturedPosts, {
    featuredPosts: featuredPosts
  }))));
}
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head({data}) {
  const post = data.mdx.frontmatter;
  return React.createElement("title", null, post.title);
}
export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        title
        slug
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allMdx(
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { title: { ne: $id } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
        }
        id
        fields {
          source
        }
      }
    }
  }
`;
